<template>
  <div class="entry-container">
    <div class="nav">
      <div class="nav-title">未来社区智慧服务平台</div>
      <div class="nav-list">
        <div class="nav-list-item nav-selected">首页</div>
        <router-link class="nav-list-item" :to="{ name: 'login5' }"
          >开放平台</router-link
        >
        <router-link class="nav-list-item" :to="{ name: 'login6' }"
          >服务商</router-link
        >
        <router-link class="nav-list-item" :to="{ name: 'login4' }"
          >社区工作台</router-link
        >
      </div>
    </div>
    <img class="headpic" src="./images/head.png" alt="" />
    <div class="body-box">
      <div class="side">
        <div class="side-wrapper">
          <div class="sub">九大场景</div>
          <div
            class="tab-item"
            :class="selectedTab === tab.id && 'tab-selected'"
            @click="handleChangeTab(tab.id)"
            v-for="tab in tabs"
            :key="tab.id"
          >
            <img
              class="tab-icon"
              :src="tab.id == selectedTab ? tab.iconSelected : tab.icon"
              alt=""
            />
            {{ tab.text }}
          </div>
        </div>
      </div>
      <div class="main">
        <!-- <div class="title">{{ tabs.find(v => v.id == selectedTab).text }}</div> -->
        <div class="main-wrapper ">
          <div class="main-item" v-for="obj in objs" :key="obj.id">
            <img class="main-img" :src="obj.img" />
            <div class="main-content">
              <div class="main-title">{{ obj.title }}</div>
              <div class="main-desc">{{ obj.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      objs: [],
      selectedTab: 1,
      tabs: [
        {
          text: "邻里场景",
          id: 1,
          icon: require("./images/dia-b.svg"),
          iconSelected: require("./images/dia.svg"),
        },
        {
          text: "教育场景",
          id: 2,
          iconSelected: require("./images/edu-b.svg"),
          icon: require("./images/edu.svg")

        },
        {
          text: "健康场景",
          id: 3,
          iconSelected: require("./images/health-b.svg"),
          icon: require("./images/health.svg")

        },
        {
          text: "创业场景",
          id: 4,
          iconSelected: require("./images/building-b.svg"),
          icon: require("./images/building.svg")

        },
        {
          text: "建筑场景",
          id: 5,
          iconSelected: require("./images/build-b.svg"),
          icon: require("./images/build.svg")

        },
        {
          text: "交通场景",
          id: 6,
          iconSelected: require("./images/bus-b.svg"),
          icon: require("./images/bus.svg")

        },
        {
          text: "低碳场景",
          id: 7,
          iconSelected: require("./images/carb-b.svg"),
          icon: require("./images/carb.svg")

        },
        {
          text: "服务场景",
          id: 8,
          iconSelected: require("./images/serve-b.svg"),
          icon: require("./images/serve.svg")

        },
        {
          text: "治理场景",
          id: 9,
          iconSelected: require("./images/set-b.svg"),
          icon: require("./images/set.svg")

        },

      ]
    }
  },
  methods: {
    handleChangeTab(id) {
      this.selectedTab = id
    },
    goTo(name) {
      location.href = name
    }
  },
  mounted() {

    this.objs = Array(100).fill({})

    this.objs.forEach((v, i) => {
      this.objs[i] = { img: "./img", title: "项目名称", id: i, desc: "项目介绍介绍介绍项目介绍介绍介绍项目介绍介绍介绍项目介绍介绍介绍项目介绍介绍介绍项目介绍介绍介绍" }
    });


  }
}
</script>

<style lang='scss' scoped>
.entry-container {
  .nav {
    position: fixed;
    color: #ffffff;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4.4vw;
    display: flex;
    justify-content: space-between;
    .nav-title {
      display: flex;
      align-items: center;
      padding-left: 2.2vw;
      justify-content: center;
      font-size: 1.1vw;
    }
    .nav-list {
      display: flex;
      font-size: 1vw;
      padding-right: 1.7vw;
      .nav-list-item {
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
          color: white;
          text-decoration: none;
          padding: 0 1.1vw;
          display: flex;
          align-items: center;
          justify-content: center;
          &.nav-selected {
            color: #43a8ff;
          }
        }
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .headpic {
    width: 100vw;
  }
  .body-box {
    padding: 0 1.7vw 2.5vw;
    padding-top: 2.3vw;

    display: flex;
    height: 100%;
    position: relative;
    flex: 1;
    overflow: hidden;
    background-color: #f0f2f5;
    .side {
      //   padding-top: 2.3vw;
      height: 100%;
      overflow-y: auto;
      width: 13.1vw;
      flex-shrink: 0;
      background-color: #ffffff;

      .side-wrapper {
        align-self: flex-start;
        font-size: 16px;
        .sub {
          height: 3.1vw;
          display: flex;
          color: #909399;
          align-items: center;
          padding-right: 1em;
          padding-left: 2.4vw;

          justify-content: flex-start;
        }
        .tab-item {
          line-height: 28.6px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0.6vw 0;
          padding-right: 1em;
          padding-left: 2.4vw;

          cursor: pointer;
          .tab-icon {
            height: 24px;
            width: 24px;
            color: #909399;
            padding-right: 1em;
          }
          &.tab-selected {
            color: #43a8ff;
            background: #e3f2ff;
            .tab-icon {
              color: #43a8ff;
            }
          }
        }
      }
    }
    .main {
      flex-grow: 1;
      padding-left: 1.4vw;
      //   padding-top: 2.4vw;
      box-sizing: border-box;
      //   padding-top: 2.3vw;
      height: 100%;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      .title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 2.4vw;
        padding-left: 1.4vw;
      }
      .main-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        .main-item {
          width: 26.5vw;
          height: 7.1vw;
          margin-top: 0.9vw;
          margin-right: 0.8vw;
          padding: 1.1vw 0.8vw;
          background: #ffffff;
          transition: transform 0.3s ease;
          box-sizing: border-box;
          display: flex;
          .main-img {
            height: 100%;
            width: 8.8vw;
            background: #43a8ff;
          }
          .main-title {
            font-size: 14px;
          }
          .main-content {
            text-align: left;
            padding-left: 0.8vw;
            flex: 1;
            position: relative;

            .main-desc {
              color: #909399;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              position: absolute;
              bottom: 0;
              left: 0.8vw;
              font-size: 12px;
              overflow: hidden;
              -webkit-line-clamp: 2;
            }
          }
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            margin-top: 0;
          }
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
          &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
              2px 0px 6px rgba(0, 0, 0, 0.1);
            transform: translateY(-0.4vw);
          }
        }
      }
    }
  }
}
</style>