var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "entry-container" }, [
    _c("div", { staticClass: "nav" }, [
      _c("div", { staticClass: "nav-title" }, [_vm._v("未来社区智慧服务平台")]),
      _c(
        "div",
        { staticClass: "nav-list" },
        [
          _c("div", { staticClass: "nav-list-item nav-selected" }, [
            _vm._v("首页"),
          ]),
          _c(
            "router-link",
            { staticClass: "nav-list-item", attrs: { to: { name: "login5" } } },
            [_vm._v("开放平台")]
          ),
          _c(
            "router-link",
            { staticClass: "nav-list-item", attrs: { to: { name: "login6" } } },
            [_vm._v("服务商")]
          ),
          _c(
            "router-link",
            { staticClass: "nav-list-item", attrs: { to: { name: "login4" } } },
            [_vm._v("社区工作台")]
          ),
        ],
        1
      ),
    ]),
    _c("img", {
      staticClass: "headpic",
      attrs: { src: require("./images/head.png"), alt: "" },
    }),
    _c("div", { staticClass: "body-box" }, [
      _c("div", { staticClass: "side" }, [
        _c(
          "div",
          { staticClass: "side-wrapper" },
          [
            _c("div", { staticClass: "sub" }, [_vm._v("九大场景")]),
            _vm._l(_vm.tabs, function (tab) {
              return _c(
                "div",
                {
                  key: tab.id,
                  staticClass: "tab-item",
                  class: _vm.selectedTab === tab.id && "tab-selected",
                  on: {
                    click: function ($event) {
                      return _vm.handleChangeTab(tab.id)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "tab-icon",
                    attrs: {
                      src:
                        tab.id == _vm.selectedTab ? tab.iconSelected : tab.icon,
                      alt: "",
                    },
                  }),
                  _vm._v("\n          " + _vm._s(tab.text) + "\n        "),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          { staticClass: "main-wrapper" },
          _vm._l(_vm.objs, function (obj) {
            return _c("div", { key: obj.id, staticClass: "main-item" }, [
              _c("img", { staticClass: "main-img", attrs: { src: obj.img } }),
              _c("div", { staticClass: "main-content" }, [
                _c("div", { staticClass: "main-title" }, [
                  _vm._v(_vm._s(obj.title)),
                ]),
                _c("div", { staticClass: "main-desc" }, [
                  _vm._v(_vm._s(obj.desc)),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }